@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Satoshi;
  font-weight: 700;
  src: url("../public/fonts/Satoshi-Bold.eot");
  src: url("../public/fonts/Satoshi-Bold.ttf");
  src: url("../public/fonts/Satoshi-Bold.woff");
  src: url("../public/fonts/Satoshi-Bold.woff2");
  src: url("../public/fonts/Satoshi-Bold.otf");
}

@font-face {
  font-family: Satoshi;
  font-weight: 500;
  src: url("../public/fonts/Satoshi-Medium.eot");
  src: url("../public/fonts/Satoshi-Medium.otf");
  src: url("../public/fonts/Satoshi-Medium.ttf");
  src: url("../public/fonts/Satoshi-Medium.woff");
  src: url("../public/fonts/Satoshi-Medium.woff2");
}
@font-face {
  font-family: Satoshi;
  font-weight: 400;
  src: url("../public/fonts/Satoshi-Regular.eot");
  src: url("../public/fonts/Satoshi-Regular.otf");
  src: url("../public/fonts/Satoshi-Regular.ttf");
  src: url("../public/fonts/Satoshi-Regular.woff");
  src: url("../public/fonts/Satoshi-Regular.woff2");
}
@layer components {
  body {
    @apply overflow-x-hidden;
    font-family: "Satoshi", "sans-serif";
  }
  .modal_scroll::-webkit-scrollbar {
    width: 3px;
  }

  .modal_scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.021);
  }

  .modal_scroll::-webkit-scrollbar-thumb {
    background-color: rgba(169, 169, 169, 0.253);
    outline: 1px solid rgba(112, 128, 144, 0.068);
  }
  .landing-page-header {
    background-image: url("./assets/img/bg.svg");
  }
  .landing-page-car {
    background-image: url("./assets/img/curve.png");
  }

  html {
    @apply scroll-smooth;
  }
  .box-shadow {
    box-shadow: 1px 4px 7px #0c203c;
  }
  .list li::before {
    content: "-";
    padding-right: 0.5rem;
  }
  .bg_design {
    background-image: url(./assets/img/bg.svg);
    background-size: 100vw 90%;
    background-position: center top;
  }
  .bg_gradient {
    background-image: url(./assets/img/grd.svg);
    background-size: 100% 90%;
    background-position: center top;
  }

  .border-draw-animation {
    animation-name: border-draw-animation;
    animation-duration: 2s; /* Set the duration in seconds */
    /* Add other animation properties as needed (e.g., timing function, delay, etc.) */
  }

  @keyframes border-draw-animation {
    from {
      border-color: white;
    }
    to {
      border-color: #1c9fda; /* Set your desired border color */
    }
  }

  div:has(> div#payment-form) {
    /* styling for div elements that are children of #my-parent */
    button {
      background-color: #0c203c;
    }

    button {
      background: #0c203c;
      border-radius: 8px;
      color: white;
      border: 0;
      padding: 12px 16px;
      margin-top: 16px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.2s ease;
      display: block;
    }
    button:hover {
      filter: contrast(115%);
    }
    button:active {
      transform: translateY(0px) scale(0.98);
      filter: brightness(0.9);
    }
    button:disabled {
      opacity: 0.5;
      cursor: none;
    }

    input,
    select {
      display: block;
      font-size: 1.1em;
      width: 100%;
      margin-bottom: 10px;
    }

    label {
      display: block;
    }

    a {
      color: #0c203c;
      font-weight: 900;
    }

    small {
      font-size: 0.6em;
    }

    fieldset,
    input,
    select {
      border: 1px solid #efefef;
    }

    #payment-form {
      border: #f6f9fc solid 1px;
      border-radius: 8px;
      padding: 20px;
      margin: 20px 0;
      box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
        0 30px 60px -30px rgb(0 0 0 / 30%);
    }

    #messages {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
      display: none; /* hide initially, then show once the first message arrives */
      background-color: #0a253c;
      color: #00d924;
      padding: 20px;
      margin: 20px 0;
      border-radius: 8px;
      font-size: 0.7em;
    }
  }
  /* *::-webkit-scrollbar {
    width: 4px;
  }
  *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.568);
    outline: "1px dotted slategrey";
  } */

  .zoom-img {
    transition: all 0.3s;
    overflow: hidden;
  }

  img {
    transition: all 0.6s ease-in;
  }
  .zoom-img:hover img {
    transform: scale(1.3);
    transition: all 0.5s;
  }

  .card {
    background-image: linear-gradient(143.07deg, #1c9fda 21.46%, #074967 88%);
    @apply mx-auto py-6 rounded;
  }

  .landing-page-card {
    background-image: linear-gradient(
      25.37deg,
      #0c203c 15.48%,
      rgba(12, 32, 60, 0) 90%
    );
  }

  .overflow-y-hidden {
    overflow-y: hidden;
  }

  .nav-active {
    @apply relative text-blue-100;
  }
  .nav-active .header::after {
    content: "";
    position: absolute;
    bottom: -25px;
    left: 0;
    @apply border-blue-100  border-b-4 w-[110%];
  }

  .white-card {
    @apply xs:w-[90%] mdLg:w-[4/5] mx-auto py-5 rounded bg-white-100;
  }
  @media only screen and (max-width: 374px) {
    .white-card {
      @apply w-[95%] mx-auto py-14 rounded bg-white-100;
    }
  }
  #p_wrap {
    white-space: pre-line;
  }

  .round-radius {
    border-radius: 50%;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="date"] {
    -webkit-align-items: center;
    display: -webkit-inline-flex;
    font-family: monospace;
    overflow: hidden;
    padding: 0;
    -webkit-padding-start: 1px;
    cursor: pointer;
    @apply font-sans text-gray-100 text-sm;
  }
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    /* display: none;
		-webkit-appearance: none; */
    width: 40px;
    height: 30px;
    margin-top: -1px;
    cursor: pointer;
    padding: 9px;
    position: absolute;
    right: 0rem;
    filter: invert(1);

    z-index: 5;
    opacity: 0;
  }

  input::-webkit-datetime-edit {
    -webkit-flex: 1;
    -webkit-user-modify: read-only !important;
    display: inline-block;
    min-width: 0;
    overflow: hidden;
  }

  input::-webkit-datetime-edit-fields-wrapper {
    -webkit-user-modify: read-only !important;
    display: inline-block;
    padding: 10px;
    z-index: -4;
    white-space: pre;
  }

  .is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    z-index: 1000000000;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #ffffff !important;

    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
    border: none !important;
    border-radius: 0 !important;
  }

  .scroll-text {
    overflow: hidden;
    position: relative;
    /* transition: all 10s infinite; */
    animation: scrollText 20s infinite alternate;
  }

  @keyframes scrollText {
    0% {
      left: -60%;
    }

    100% {
      left: 60%;
    }
  }
  @media (max-width: 500px) {
    .scroll-text {
      position: relative;
      /* transition: all 10s infinite; */
      animation: none;
    }

    @keyframes scrollText {
      0% {
        left: -500px;
      }

      100% {
        left: 500px;
      }
    }
  }
  @layer base {
    html {
      font-family: DM sans, system-ui, sans-serif;
    }
  }
}

.testimonial .slick-arrow.slick-prev,
.testimonial .slick-arrow.slick-next {
  width: 40px;
  height: 40px;
  display: flex;

  z-index: 1;
  border-radius: 5px;
  color: beige;
}
.land_btn {
  transition: all 0.25s;
  transform: translate(0%, 0%);
}

.land_btn:hover {
  background: #1784b6;
  padding-right: 60px;
}
.scrolling::-webkit-scrollbar {
  display: none;
}
.gradient_bg {
  background: linear-gradient(274.57deg, #febb02 -62.44%, #1c9fda 131.7%);
}
.scrolling::-webkit-scrollbar-thumb {
  display: none;
}
.shadows {
  box-shadow: 0px 2px 18px 1px rgba(0, 0, 0, 0.15);
}
.top_overlay {
  background: linear-gradient(
    360deg,
    #ffffff6b 29.19%,
    rgba(204, 213, 236, 0) 91.61%
  );
}
.review_overlay {
  background: linear-gradient(
    360deg,
    #fff 29.19%,
    rgba(204, 213, 236, 0) 91.61%
  );
}
.my-masonry-grid {
  display: flex;
  margin-left: -30px;
  margin-bottom: 70px;
  width: auto;
}
.shadow_box {
  box-shadow: unset !important;
}
.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}
.my-masonry-grid_column > div {
  margin-bottom: 30px;
}
.curve:before {
  content: "";
  height: 100%;
  width: 150%;
  left: -25%;
  position: absolute;
  background: #fff;
  border-radius: 50%;
  transition: all 0.8s;
}
.bar_content .testimonial .slick-prev::before,
.testimonial .slick-next::before {
  display: none;
}
.gradient_text {
  background: linear-gradient(274.57deg, #febb02 -62.44%, #1c9fda 131.7%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.testimonial .slick-arrow.slick-next {
  right: -20px;
  color: rgba(28, 158, 218);
}
.testimonial .slick-arrow.slick-prev {
  left: -30px;
}
.testimonial .slick-dots ul button:before {
  font-size: 15px;
  bottom: 10px;
  color: rgba(28, 158, 218);
}
.testimonial .slick-dots ul button::content {
  font-size: 15px;
  bottom: 10px;
  color: rgb(28, 158, 218);
}
.testimonial .slick-dots ul {
  bottom: 70px;
}

.testimonial select {
  border-right: 16px solid transparent;
}
.testimonial .slick-slider {
  top: 95px;
}

@media only screen and (max-width: 768px) {
  .testimonial .slick-slider {
    top: 120px;
  }
  .testimonial .slick-dots ul {
    bottom: 25px;
  }
}
@media only screen and (max-width: 450px) {
  .testimonial .slick-slider {
    top: 150px;
  }
}

.home-packages .slick-slider {
  top: 2px;
  padding: 0 0.4rem;
  /* left: 20%; */
  /* transform: translateX(-50%); */
}

.header-carousel .slick-slider {
  top: 2px;
  height: 100%;
  width: 100%;

  /* padding: 0 .4rem; */
  /* left: 20%; */
  /* transform: translateX(-50%); */
}

@media (max-width: 600px) {
  .header-carousel .slick-dots ul {
    display: none;
    top: 500px;
  }
}

.package-details .slick-slider {
  top: 0px;
  height: 100%;
  width: 100%;
}

@media (max-width: 600px) {
  .package-details .slick-dots ul {
    display: none;
    /* top: 500px; */
  }
}

.file-box > label {
  width: 100% !important;
}

.bar_content {
  display: block; /* Important to give the content a width */

  width: 100%;
  transform: translateX(100%); /* Animation start out of the screen */

  /* Add the animation */

  animation: move 40s linear infinite
    /* infinite make reapeat the animation indefinitely */;
}
@media (max-width: 500px) {
  .bar_content {
    /* Add the animation */
    animation: move 20s linear infinite
      /* infinite make reapeat the animation indefinitely */;
  }
  @keyframes move {
    to {
      transform: translateX(-180%);
    }
  }
}
/* Create the animation */
@keyframes move {
  to {
    transform: translateX(-50%);
  }
}

.carousel-container {
  position: relative;
}

.carousel-image-container {
  display: flex;
  overflow: hidden;
}

.carousel-image {
  flex: 0 0 100%;
  transition: transform 0.5s ease-in-out;
}

.carousel-image.active {
  transform: translateX(calc(var(--currentIndex) * -100%));
}

.carousel-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.carousel-button {
  background-color: blue;
  color: white;
  padding: 10px;
  border: none;
  margin: 0 5px;
  cursor: pointer;
}

.carousel-button.active {
  background-color: red;
}
.cursor {
  position: absolute;
  width: 16px;
  top: 0;
  right: 0;
  z-index: 2;
  left: 50%;
  height: 16px;
  animation-iteration-count: infinite;
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
}
.cursor1 {
  animation-name: cursor1-move;
  animation-duration: 2s;
}
.cursor2 {
  animation-name: cursor2-move;
  animation-duration: 3s;
}
.cursor3 {
  animation-name: cursor3-move;
  animation-duration: 4s;
}
.cursor4 {
  animation-name: cursor4-move;
  animation-duration: 5s;
}
.input_change:hover .btn_bg {
  background: #1c9fda;
  transition: ease-in-out;
  transition-duration: 400ms;
}
.cursor5 {
  animation-name: cursor5-move;
  animation-duration: 3s;
}
.cursor6 {
  animation-name: cursor6-move;
  animation-duration: 3s;
}
.cursor7 {
  animation-name: cursor7-move;
  animation-duration: 3s;
}
.cursor8 {
  animation-name: cursor8-move;
  animation-duration: 4s;
}

@keyframes cursor1-move {
  0% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(-300%, 780%);
  }
}

@keyframes cursor2-move {
  0% {
    transform: translate(-300%, 730%);
  }
  100% {
    transform: translate(950%, 300%);
  }
}

@keyframes cursor3-move {
  0% {
    transform: translate(0%, 2300%);
  }
  20% {
    transform: translate(-800%, 1100%);
  }
  40% {
    transform: translate(650%, 1100%);
  }
  60% {
    transform: translate(-800%, 1600%);
  }
  80% {
    transform: translate(650%, 1600%);
  }
  100% {
    transform: translate(0%, 2300%);
  }
}
@keyframes cursor4-move {
  0% {
    transform: translate(950%, 300%);
  }
  20% {
    transform: translate(-800%, 1100%);
  }
  40% {
    transform: translate(650%, 1100%);
  }
  60% {
    transform: translate(-800%, 1600%);
  }
  80% {
    transform: translate(650%, 1600%);
  }
  100% {
    transform: translate(0%, 2300%);
  }
}

@keyframes cursor5-move {
  0% {
    transform: translate(0%, 730%);
  }
  25% {
    transform: translate(0%, 730%);
  }
  50% {
    transform: translate(-700%, 2200%);
  }
  75% {
    transform: translate(-700%, 2200%);
  }
  100% {
    transform: translate(-700%, 2200%);
  }
}

@keyframes cursor6-move {
  0% {
    transform: translate(-700%, 2200%);
  }
  50% {
    transform: translate(0%, 1230%);
  }
  100% {
    transform: translate(0%, 1700%);
  }
}

@keyframes cursor7-move {
  0% {
    transform: translate(0%, 1700%);
  }
  100% {
    transform: translate(-700%, 2430%);
  }
}
@keyframes cursor8-move {
  0% {
    transform: translate(-700%, 2430%);
  }
  100% {
    transform: translate(0%, 2430%);
  }
}

@media (max-width: 600px) {
  @keyframes cursor1-move {
    0% {
      transform: translate(0%, 0%);
    }
    100% {
      transform: translate(1250%, 1100%);
    }
  }
  @keyframes cursor2-move {
    0% {
      transform: translate(1250%, 1100%);
    }
    100% {
      transform: translate(-950%, 650%);
    }
  }

  @keyframes cursor7-move {
    0% {
      transform: translate(0%, 1830%);
    }
    100% {
      transform: translate(-1100%, 2500%);
    }
  }
}

@media (max-width: 438px) {
  @keyframes cursor1-move {
    0% {
      transform: translate(0%, 0%);
    }
    100% {
      transform: translate(690%, 1140%);
    }
  }

  @keyframes cursor2-move {
    0% {
      transform: translate(690%, 1140%);
    }
    100% {
      transform: translate(-450%, 650%);
    }
  }
  @keyframes cursor4-move {
    0% {
      transform: translate(950%, 300%);
    }
    20% {
      transform: translate(-800%, 1100%);
    }
    40% {
      transform: translate(650%, 1100%);
    }
    60% {
      transform: translate(-800%, 1600%);
    }
    80% {
      transform: translate(650%, 1600%);
    }
    100% {
      transform: translate(0%, 2300%);
    }
  }

  @keyframes cursor3-move {
    0% {
      transform: translate(0%, 2000%);
    }
    20% {
      transform: translate(-800%, 1150%);
    }
    40% {
      transform: translate(650%, 1150%);
    }
    60% {
      transform: translate(-800%, 1500%);
    }
    80% {
      transform: translate(650%, 1500%);
    }
    100% {
      transform: translate(0%, 2000%);
    }
  }

  @keyframes cursor4-move {
    0% {
      transform: translate(950%, 300%);
    }
    20% {
      transform: translate(-800%, 1150%);
    }
    40% {
      transform: translate(650%, 1150%);
    }
    60% {
      transform: translate(-800%, 1500%);
    }
    80% {
      transform: translate(650%, 1500%);
    }
    100% {
      transform: translate(0%, 2000%);
    }
  }

  @keyframes cursor7-move {
    0% {
      transform: translate(0%, 1700%);
    }
    100% {
      transform: translate(-500%, 2550%);
    }
  }
}

button {
  cursor: pointer;
}

.loading {
  display: flex;
  justify-content: center;
}

.loading {
  display: flex;
  justify-content: center;
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 999999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.load {
  width: 12px;
  z-index: 9999999;
  aspect-ratio: 1;
  position: relative;
  z-index: 3;
}
.load::before,
.load::after {
  content: "";
  position: absolute;
  z-index: 9999999;
  inset: 0;
  border-radius: 50%;
  background: #1c9fda;
}
.load::before {
  box-shadow: 25px 0;
  animation: ld-1 1s infinite linear;
  z-index: 9999999;
}
.load::after {
  transform: rotate(0deg) translateX(-25px);
  animation: ld-2 1s infinite linear;
  z-index: 9999999;
}

@keyframes ld-1 {
  100% {
    transform: translateX(-25px);
  }
}
@keyframes ld-2 {
  100% {
    transform: rotate(180deg) translateX(-25px);
  }
}

.blue_bg {
  background: linear-gradient(141.36deg, #1c9fda 24.16%, #074967 87.09%);
}

.gradient {
  background: rgba(18, 18, 18, 0.4);
}

.overlay {
  background: linear-gradient(to bottom, #f7f7f8, rgba(255, 255, 255, 0));
}

@media (max-width: 1099px) {
  .overlay {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f8);
  }
}

.arrow {
  animation: shake-animation 3s ease infinite;
  transform-origin: 50% 50%;
}
@keyframes shake-animation {
  0% {
    transform: scale(1) translate(0, 0);
  }
  1.78571% {
    transform: translate(5px, 0);
  }
  3.57143% {
    transform: scale(1.2) translate(0, 0);
  }
  5.35714% {
    transform: translate(5px, 0);
  }
  7.14286% {
    transform: scale(1) translate(0, 0);
  }
  8.92857% {
    transform: translate(5px, 0);
  }
  10.71429% {
    transform: scale(1.2) translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.imageAnimation {
  transition: width 0.5s ease-in-out;
  animation: zoomInOut 2s infinite;
}

@keyframes zoomInOut {
  0%,
  100% {
    width: 70px;
  }

  50% {
    width: 100px;
  }
}

.tab:hover .circle {
  color: #1c9fda;
}

.circle {
  color: red;
}

.hi::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 8px;
  background-color: unset;
}

.hi::-webkit-scrollbar-thumb {
  background-color: unset;
}

.react-calendar {
  color: #222;
  line-height: 1.5em;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation {
  display: flex;
}

.react-calendar__navigation__prev-button {
  font-weight: bold;
}

.react-calendar__navigation__next-button {
  font-weight: bold;
}

.react-calendar__month-view {
  padding-bottom: 20px;
}
.react-calendar__month-view__weekdays {
  padding: 20px;
  font-size: 12px;
  text-transform: uppercase;
  color: #828282;
}
abbr[title] {
  text-decoration: unset !important;
}
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__label {
  font-weight: bold;
}
.react-calendar__navigation button {
  color: #333333;
  min-width: 44px;
  font-size: 16px;
  margin-top: 8px;
}
.react-calendar__tile {
  padding: 5px;
  margin-bottom: 5px;
  color: #000;
}

.react-calendar__tile--now {
  background: #f5f5f5;
  border-radius: 6px;
  font-weight: bold;
  color: gray;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  border-radius: 6px;
  font-weight: bold;
  color: #1c9fda;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
  background: #1c9fda;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1c9fda;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--rangeStart {
  border-radius: 6px;
  background: #1c9fda;
  color: white;
}
.react-calendar__tile--rangeEnd {
  border-radius: 6px;
  background: #1c9fda;
  color: white;
}

.pending {
  display: inline-block;
}
.pending-0:after {
  content: "";
  color: #000;
}
.pending-1:after {
  content: " •";
  color: #000;
}
.pending-2:after {
  content: " • •";
  color: #000;
}
.pending-3:after {
  content: " • • •";
  color: #000;
}
.bot::-webkit-scrollbar {
  width: 6px;
  border-radius: "8px";
  background-color: rgba(0, 0, 0, 0.05);
}
.bot::-webkit-scrollbar-thumb {
  background-color: #0f172b;
}
